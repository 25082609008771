<template>
  <div class="generic-list scroll-container">
    <CategoryTitle :category="category" />

    <OrdersTableContainer :pageFilter="pageFilter" />
  </div>
</template>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import OrdersTableContainer from "@/components/orders/OrdersTableContainer.vue";

import categoryMixin from "~/mixins/category";

// import { mapGetters } from "vuex";

export default {
  name: "Orders",
  data() {
    return {};
  },
  mixins: [categoryMixin],
  components: {
    CategoryTitle,
    OrdersTableContainer
  },
  computed: {
    // ...mapGetters({
    //   pageFilter: "category/pageFilter"
    // })
  },
  methods: {},
  mounted() {}
};
</script>
